import React, { useRef, useLayoutEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	word-break: keep-all;
	white-space: nowrap;
	font-weight: 600;
	color: ${props => props.theme.neutrals.light};
	font-size: 16px;
	text-decoration: none;
	padding: 0 8px;
	position: relative;
	z-index: 1;
	border-bottom: 2px solid transparent;
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	&:hover {
		text-decoration: none;
		color: ${props => props.theme.neutrals.darker};
		border-bottom: 2px solid ${props => props.theme.primary.secondaryText};
	}
	&.tabnav__active {
		font-weight: bold;
		color: ${props => props.theme.primary.dark};
		border-bottom: 2px solid ${props => props.theme.primary.dark};
	}
`;

export function TabNav({ link, label }) {
	return (
		<StyledLink activeClassName="tabnav__active" to={link}>
			{label}
		</StyledLink>
	);
}

const TabNavsOuter = styled.div`
	position: relative;
`;

const TabNavsWrap = styled.nav`
	display: grid;
	margin: 0 auto;
	grid-gap: 16px;
	grid-template-columns: 16px;
	height: 46px;
	margin: 0 -16px;
	grid-template-rows: minmax(27px, 1fr);
	grid-auto-flow: column;
	grid-auto-columns: min-content;

	overflow-x: auto;
	scroll-snap-type: x mandatory;

	&::before,
	&::after {
		content: '';
		width: 16px;
	}
`;

const TabNavsBorder = styled.div`
	height: 2px;
	position: absolute;
	bottom: 0;
	left: 16px;
	right: 16px;
	width: auto;
	border-radius: 2px;
	background-color: ${props => props.theme.neutrals.lightTwo};
`;

export function TabNavs({ children, pathname }) {
	const ref = useRef();
	useLayoutEffect(() => {
		const { current: elm } = ref;
		if (elm === null) {
			return;
		}
		// find the one that has the active class and keep on adding width + gutter
		// until it reaches
		let scrollLeft = 0;
		const anchors = Array.from(elm.querySelectorAll('a'));
		anchors.every(anchor => {
			if (anchor.classList.contains('tabnav__active')) {
				return false;
			}
			scrollLeft += anchor.offsetWidth + 16;
			return true;
		});
		elm.scrollLeft = scrollLeft;
	}, [ref, pathname]);
	return (
		<TabNavsOuter>
			<TabNavsWrap ref={ref}>{children}</TabNavsWrap>
			<TabNavsBorder />
		</TabNavsOuter>
	);
}
