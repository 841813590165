import React from 'react';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import styled from 'styled-components';

import Layout from '../components/Layout';
import TypoContent from '../components/TypoContent';
import Header from '../components/Header';
import SEO from '../components/SEO';
import { TabNavs, TabNav } from '../components/TabNav';
import { ReactComponent as CalendarIcon } from '../svgs/icons/calendar.svg';
import ButtonLink from '../components/ButtonLink';
import Alert from '../components/Alert';

const Description = styled.p`
	display: flex;
	align-items: center;
	svg {
		height: 18px;
		width: 18px;
		fill: ${props => props.theme.neutrals.secondaryText};
		margin: -1px 15px 0 0;
	}
`;

const Footer = styled.footer`
	padding: 0;
	margin: ${props => props.theme.gutter.small}px 0 0 0;
	${ButtonLink} {
		max-width: 300px;
		padding: 0 15px;
	}
	@media ${props => props.theme.breakpoints.tabletQuery} {
		margin: ${props => props.theme.gutter.large}px 0 0 0;
	}

	@media ${props => props.theme.breakpoints.desktopQuery} {
		padding: ${props => props.theme.gutter.large}px 0 0 0;
		border-top: 2px solid ${props => props.theme.neutrals.lightTwo};
	}
`;

export default function PageTemplate(props) {
	const {
		data: {
			mdx,
			allMdx: { edges: allMdxEdges },
		},
		location: { pathname },
	} = props;
	const {
		frontmatter: {
			title,
			lastModified,
			buttonLink,
			buttonLabel,
			banner,
			alertIcon,
			alertTitle,
			alertMessage,
			seoDescription,
		},
	} = mdx;
	let description = null;
	if (lastModified && lastModified !== null) {
		description = (
			<Description>
				<CalendarIcon />
				<span>Last reviewed: {lastModified}</span>
			</Description>
		);
	}
	return (
		<Layout {...props} hasFooter={false} banner={banner} noBtnDropdown>
			<Header description={description}>Legal / {title}</Header>
			{alertMessage !== null && alertMessage !== '' ? (
				<Alert
					icon={alertIcon}
					message={alertMessage}
					title={alertTitle}
				/>
			) : null}
			<TabNavs pathname={pathname}>
				{allMdxEdges.map(({ node }) => (
					<TabNav
						key={node.fields.slug}
						label={node.frontmatter.navTitle}
						link={node.fields.slug}
					/>
				))}
			</TabNavs>
			<SEO title={title} description={seoDescription || null} />
			<TypoContent>
				<MDXRenderer>{mdx.body}</MDXRenderer>
			</TypoContent>
			{buttonLink && buttonLink !== null ? (
				<Footer>
					<ButtonLink backgroundBlue href={buttonLink}>
						{buttonLabel || 'Contact the HostJane Team'}
					</ButtonLink>
				</Footer>
			) : null}
		</Layout>
	);
}

export const pageQuery = graphql`
	query LegalPostQuery($id: String) {
		mdx(id: { eq: $id }) {
			frontmatter {
				title
				lastModified
				buttonLink
				buttonLabel
				banner
				alertIcon
				alertTitle
				alertMessage
				seoDescription
			}
			body
		}
		allMdx(
			sort: { fields: frontmatter___order, order: ASC }
			filter: { fileAbsolutePath: { glob: "**/content/legal/**" } }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						navTitle
					}
				}
			}
		}
	}
`;
